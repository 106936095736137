import {Moralis} from "moralis"
import {useEffect, useState} from "react"
import {ABI} from "./abi"
import Web3 from "web3"

export const ConnectButton = () => {
    const [connected, setConnected] = useState(false)
    const [pending, setPending] = useState(false)
    const [feedback, setFeedback] = useState(``);
    const [saleState, setSaleState] = useState(0);
    const [price, setPrice] = useState(process.env.REACT_APP_PUBLIC_SALE_PRICE);
    const [whitelist, setWhitelist] = useState(false);
    const [count, setCount] = useState(1);
    const [metamaskInstalled, setMetamaskInstalled] = useState(false);

    const options = {
        contractAddress: process.env.REACT_APP_CONTRACT_ADDRESS,
        abi: ABI,
    };

    useEffect(async() => {
        try {
            setMetamaskInstalled(await Moralis.isMetaMaskInstalled())
        } catch (e) {

        }
    }, [])

    Moralis.onWeb3Enabled(async (result) => {
        try {
            if (connected) return
            if (Moralis.chainId !== process.env.REACT_APP_NETWORK) {
                Moralis.switchNetwork(process.env.REACT_APP_NETWORK).then(async () => {
                    await Moralis.enableWeb3()
                }).catch(async () => {
                    await Moralis.deactivateWeb3()
                    setConnected(false)
                })
            } else {
                setConnected(true)

                const state = await Moralis.executeFunction({functionName: "state", ...options})
                setSaleState(state)

                const whitelist = await Moralis.executeFunction({functionName: "whitelist", params: {addr: result.account}, ...options})
                setWhitelist(whitelist)

                if (state === 1) {
                    setPrice(process.env.REACT_APP_PRESALE_PRICE)
                }
            }
        } catch (e) {}
    });

    Moralis.onAccountChanged((result) => {
        if (!result) {
            setConnected(false)
        }
    })

    Moralis.onChainChanged(async (chainId) => {
        if (chainId !== process.env.REACT_APP_NETWORK) {
            setConnected(false)

            try {
                await Moralis.deactivateWeb3()
            } catch (e) {}
        }
    })

    Moralis.onDisconnect((result) => {
        setConnected(false)
    })

    const setTimingFeedback = (text) => {
        setFeedback(text)
        setTimeout(() => {
            setFeedback("")
        }, 10000);
    }

    const increment = () => {
        let newCount = count + 1;
        if (newCount > (saleState === 1 ? 2 : 10)) {
            setCount((saleState === 1 ? 2 : 10))
        } else {
            setCount(newCount)
        }
    }

    const decrement = () => {
        let newCount = count - 1;
        if (newCount < 1) {
            setCount(1)
        } else {
            setCount(newCount)
        }
    }

    const walletConnect = async () => {
      if (!connected) {
          try {
              await Moralis.enableWeb3({
                  chainId: 1,
                  provider: "walletconnect",
                  mobileLinks: ["rainbow", "metamask", "argent", "trust", "imtoken", "pillar"]
              })
          } catch (e) {
              await Moralis.deactivateWeb3()
          }
      }
    }

    const mintOrConnect = async () => {
        if (connected) {
            if (pending) return
            if (!saleState) {
                setTimingFeedback("Mint Disabled")
                return
            }

            if (saleState === 1 && !whitelist) {
                setTimingFeedback("Only for Whitelisted users")
                return
            }

            setPending(true)
            const web3 = new Web3(Moralis.provider)
            const SmartContract = new web3.eth.Contract(
                options.abi,
                options.contractAddress
            )

            SmartContract.methods
                .mint(count)
                .send({
                    to: options.contractAddress,
                    from: Moralis.account,
                    value: Moralis.Units.ETH(price * count),
                })
                .once("error", (err) => {
                    setTimingFeedback("Sorry, something went wrong please try again later.")
                    setPending(false)
                })
                .then(() => {
                    setPending(false)
                    setTimingFeedback(`WOW, the Femmes Bizarre NFT is yours!`)
                })
        } else {
            try {
                await Moralis.enableWeb3()
            } catch (e) {
                await Moralis.deactivateWeb3()
            }
        }
    }


    return (
        <>
            {connected ? (
                <div style={{marginBottom: "20px", marginLeft: "80px"}}>
                    <a
                        href="#"
                        className="button-hero-desktop w-button"
                        style={{cursor: "pointer", width: "30px", height: "30px", padding: "5px", marginRight: "10px"}}
                        onClick={() => decrement()}
                    >
                        -
                    </a>
                    <a
                        href="#"
                        className="button-hero-desktop w-button"
                        style={{color: "#ff035d", background: "white", width: "30px", height: "30px", padding: "5px", marginRight: "10px"}}
                        onClick={(e) => e.preventDefault()}
                    >
                        {count}
                    </a>
                    <a
                        href="#"
                        className="button-hero-desktop w-button"
                        style={{cursor: "pointer", width: "30px", height: "30px", padding: "5px"}}
                        onClick={() => increment()}
                    >
                        +
                    </a>
                </div>
            ):(
                <></>
            )}
            {metamaskInstalled && !connected ? (
                <a
                    href="#"
                    className="button-hero-desktop w-button"
                    style={{cursor: "pointer"}}
                    onClick={() => mintOrConnect()}
                >
                    Connect Metamask
                </a>
            ) : (
                <></>
            )}
            {!connected ? (
                <a
                    href="#"
                    className="button-hero-desktop w-button"
                    style={{cursor: "pointer", width: metamaskInstalled ? "70px" : "270px", color: "black", position: "relative", paddingTop: "15px",
                        marginLeft: metamaskInstalled ? "20px" : "0px", background: "white", zIndex: 9999}}
                    onClick={() => walletConnect()}
                >
                    <img
                        src="data:image/svg+xml,%3Csvg height='185' viewBox='0 0 300 185' width='300' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m61.4385429 36.2562612c48.9112241-47.8881663 128.2119871-47.8881663 177.1232091 0l5.886545 5.7634174c2.445561 2.3944081 2.445561 6.2765112 0 8.6709204l-20.136695 19.715503c-1.222781 1.1972051-3.2053 1.1972051-4.428081 0l-8.100584-7.9311479c-34.121692-33.4079817-89.443886-33.4079817-123.5655788 0l-8.6750562 8.4936051c-1.2227816 1.1972041-3.205301 1.1972041-4.4280806 0l-20.1366949-19.7155031c-2.4455612-2.3944092-2.4455612-6.2765122 0-8.6709204zm218.7677961 40.7737449 17.921697 17.546897c2.445549 2.3943969 2.445563 6.2764769.000031 8.6708899l-80.810171 79.121134c-2.445544 2.394426-6.410582 2.394453-8.85616.000062-.00001-.00001-.000022-.000022-.000032-.000032l-57.354143-56.154572c-.61139-.598602-1.60265-.598602-2.21404 0-.000004.000004-.000007.000008-.000011.000011l-57.3529212 56.154531c-2.4455368 2.394432-6.4105755 2.394472-8.8561612.000087-.0000143-.000014-.0000296-.000028-.0000449-.000044l-80.81241943-79.122185c-2.44556021-2.394408-2.44556021-6.2765115 0-8.6709197l17.92172963-17.5468673c2.4455602-2.3944082 6.4105989-2.3944082 8.8561602 0l57.3549775 56.155357c.6113908.598602 1.602649.598602 2.2140398 0 .0000092-.000009.0000174-.000017.0000265-.000024l57.3521031-56.155333c2.445505-2.3944633 6.410544-2.3945531 8.856161-.0002.000034.0000336.000068.0000673.000101.000101l57.354902 56.155432c.61139.598601 1.60265.598601 2.21404 0l57.353975-56.1543249c2.445561-2.3944092 6.410599-2.3944092 8.85616 0z' fill='%233b99fc'/%3E%3C/svg%3E"
                        style={{width: "32px", height: "32px", marginRight: metamaskInstalled ? "0px" : "15px"}} />
                    {metamaskInstalled ? '' : 'WalletConnect'}
                </a>
            ) : (
                <a
                    href="#"
                    className="button-hero-desktop w-button"
                    style={{cursor: "pointer"}}
                    onClick={() => mintOrConnect()}
                >
                    {pending ? 'Busy...' : connected ? saleState === 1 ? 'Whitelist Mint' : 'Mint' : 'Connect Metamask'}
                </a>
            )}
            <p style={{color: "#ff035d", zIndex: 999, position: "relative", background: "white",
                marginTop: "10px", borderRadius: "5px",
                maxWidth: "270px", padding: "3px",
                textAlign: "center", opacity: feedback ? 1 : 0}}>
                {feedback}
            </p>
        </>
    )
}