import {ConnectButton} from "./ConnectButton";

function App() {
  return (
        <>
          <div className="container---hero-desktop w-container">
            <div className="table-hero-desktop w-row">
              <div className="column-hero-one-desktop w-col w-col-7">
                <img src={"images/girls.gif"} loading="lazy" alt="" className="image-desktop" />
                <img src={"images/girls-small.gif"} loading="lazy" alt="" className="image-tablet" />
              </div>
              <div className="column-hero-two-desktop w-col w-col-5">
                <div>
                  <a href={"https://twitter.com/femmes_bizarre/"} target={"_blank"} className="twitter-hero-desktop w-inline-block"/>
                  <a href={"http://discord.gg/femmesbizarre"} target={"_blank"} className="discrod-hero-desktop w-inline-block"/>
                  <a href={"https://opensea.io/"} target={"_blank"} className="opensea-hero-desktop w-inline-block"/>
                  <a href={"https://open.spotify.com/user/31reclnq35gywu3vs4edb22fkozq"} target={"_blank"} className="spotify-hero-desktop w-inline-block"/>
                </div>
                <div>
                  <h1 className="heading">Explore Femmes Bizarre Universe</h1>
                </div>
                <p className="paragraph-hero-desktop">
                  Handcrafted collection of NFT art pieces will lead you through the
                  world of Art &amp; Fashion. Take hand of one of these hypnotic women and go on a wild trip
                  together.
                </p>
                <div>
                  <ConnectButton/>
                </div>
                <div>
                  <img src={"images/decor-yay.svg"} loading="lazy" width="180" alt="" className="decor-yay-desktop"/>
                </div>
              </div>
            </div>
          </div>
          <div className="container---hero-mobile w-container">
            <div className="hero-one-mobile">
              <img src={"images/girls-small.gif"} loading="lazy" alt="" className="image-mobile"/>
            </div>
            <div className="hero-two-mobile">
              <div>
                <a href={"https://twitter.com/femmes_bizarre/"} target={"_blank"} className="twitter-hero-desktop w-inline-block"/>
                <a href={"https://discord.com/"} target={"_blank"} className="discrod-hero-desktop w-inline-block"/>
                <a href={"https://opensea.io/"} target={"_blank"} className="opensea-hero-desktop w-inline-block"/>
                <a href={"https://open.spotify.com/user/31reclnq35gywu3vs4edb22fkozq"} target={"_blank"} className="spotify-hero-desktop w-inline-block"/>
              </div>
              <div>
                <h1 className="heading">Explore Femmes Bizarre Universe</h1>
              </div>
              <p className="paragraph-hero-mobile">
                Handcrafted collection of NFT art pieces will lead you through the
                world of Art &amp; Fashion. Take hand of one of these hypnotic women and go on a wild trip together.
              </p>
              <div>
                <ConnectButton/>
              </div>
              <div className="div-block-15">
                <img src={"images/decor-yay.svg"} loading="lazy" width="180" alt="" className="decor-yay-desktop" />
              </div>
            </div>
          </div>
        </>
      );
}

export default App;
