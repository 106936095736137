import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { MoralisProvider } from "react-moralis";

ReactDOM.render(
  <React.StrictMode>
      <MoralisProvider
          serverUrl="https://79n0day7y4ub.usemoralis.com:2053/server"
          appId="l0XiY3Co9G6jWRvsvuStXxhF58cFjQh4Bo2RJtF9"
      >
          <App />
      </MoralisProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
